import React, { useState } from "react"
import { FaLink } from "react-icons/fa"
import { Tooltip } from "@chakra-ui/react"
import { copyToClipboard } from "../util/util"
import { Icon } from "./Icon"

export const CopyURLToggle = ({ ...rest }) => {
  const [hasCopied, setHasCopied] = useState(false)

  function copyOnClick() {
    if (hasCopied) return

    copyToClipboard(window.location.href)
    setHasCopied(true)

    setTimeout(() => {
      setHasCopied(false)
    }, 1000)
  }

  return (
    <Tooltip shouldWrapChildren label="Copied!" isOpen={hasCopied}>
      <Icon onClick={copyOnClick} as={FaLink} cursor="pointer" {...rest} />
    </Tooltip>
  )
}
