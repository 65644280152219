import React from "react"
import { Icon as ChakraIcon } from "@chakra-ui/react"

export const Icon = ({ fill = "#73737D", ...rest }) => {
  return (
    <ChakraIcon
      _hover={{
        fill: "brand.primary",
      }}
      transition="fill 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955)"
      color={fill}
      {...rest}
    />
  )
}
