import React from "react"
import { Flex, Spacer } from "@chakra-ui/react"
import { CopyURLToggle } from "./Control.CopyURLToggle"
import { ColorModeToggle } from "./Control.ColorModeToggle"

export const Control = () => (
  <Flex
    m="auto"
    w="100%"
    fontSize="xl"
    maxW={["100%", "100%", "80%", "1100px"]}
    justify="end"
    align="end"
    direction="row"
  >
    <Spacer />
    <CopyURLToggle mx="3" my="8" />
    <ColorModeToggle m="3" my="8" />
  </Flex>
)
