import { Box, Flex, Stack, Text } from "@chakra-ui/react"
import * as React from "react"
import { graphql } from "gatsby"
import { Control } from "../components/Control"
import { Hero } from "../components/Hero"
import { ArticleListItem } from "../components/Article.ListItem"
import { Helmet } from "react-helmet"

export default function Home({ data }) {
  // const { edges: posts } = data.allMdx

  return (
    <Flex minHeight="100vh" direction="column">
      <Box>
        <Control />
        {/* <Box p="6">
          <Hero />
        </Box> */}
        <Box textAlign="center" p="6" maxW="700px" m="auto">
          <Text as="em">Nothing here yet. Check back soon!</Text>
        </Box>
      </Box>
      {/* <Flex justify="center" flex="1">
        <Flex pb="2" align="flex-end">
          <Text fontSize="xs" as="em">TOSA AS - 925848506 MVA</Text>
        </Flex>
      </Flex> */}
      {/* <Stack spacing="8" direction="column" maxW="700px" m="auto" p="6" as="ul">
        {posts.map(({ node: post }) => (
          <ArticleListItem key={post.id} post={post} />
        ))}
      </Stack> */}
    </Flex>
  )
}

// export const pageQuery = graphql`
//   query blogIndex {
//     allMdx {
//       edges {
//         node {
//           id
//           excerpt
//           timeToRead
//           frontmatter {
//             title
//           }
//           fields {
//             slug
//           }
//         }
//       }
//     }
//   }
// `
