import { useColorMode } from "@chakra-ui/react"
import React from "react"
import { FaMoon, FaSun } from "react-icons/fa"
import { Icon } from "./Icon"

export const ColorModeToggle = ({ ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <Icon
      cursor="pointer"
      aria-label="Toggle dark and light mode"
      onClick={toggleColorMode}
      as={colorMode === `dark` ? FaSun : FaMoon}
      {...rest}
    />
  )
}
